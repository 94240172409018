import { DevTool } from "@hookform/devtools";
import {
	Box,
	Button,
	FormControl,
	FormControlLabel,
	Grid,
	Hidden,
	IconButton,
	InputLabel,
	LinearProgress,
	MenuItem,
	Select,
	Switch,
	TextField,
	Toolbar,
	Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useGet } from "@typesaurus/react";
import { da } from "date-fns/esm/locale";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { ArrowLeft, FileText, Save } from "react-feather";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";
import { collection } from "typesaurus";

import { firebase, firestore, GeoPoint, storage } from "../../config/firebase";
import { DatabaseContact, LocalContact } from "../../types/firebase/contacts";
import { AttachmentBox } from "../Contact/styles";
import { AttachmentUploader, Container } from "./styles";

const categories = ["poi", "health", "holy"] as const;

const contacts = collection<DatabaseContact>("contacts");

const ContactEditor = (): JSX.Element => {
	const history = useHistory();
	const { contactId } = useParams<{ contactId: string }>();
	const [contact, { loading, error }] = useGet(contacts, contactId);
	const {
		register,
		control,
		reset,
		handleSubmit,
		errors,
		formState,
		getValues,
		setValue,
	} = useForm<LocalContact>({
		defaultValues: {
			label: contact?.data.label,
			phone: contact?.data.phone ?? "",
			email: contact?.data.email ?? "",
		},
	});

	const values = getValues();

	useEffect(() => {
		reset({
			label: contact?.data.label,
			phone: contact?.data.phone ?? "",
			email: contact?.data.email ?? "",
		});
	}, [contact, loading, reset]);

	const isEditing = Boolean(contactId);

	const onSubmit: SubmitHandler<LocalContact> = async (data) => {
		if (!data.label) return;

		const contactPayload: DatabaseContact = {
			label: data.label,
			phone: data.phone,
			email: data.email,
		};

		if (isEditing) {
			firestore.collection("contacts").doc(contactId).update(contactPayload);
			history.push(`/contacts/${contactId}`);
		} else {
			const result = await firestore.collection("contacts").add(contactPayload);
			history.push(result.id);
		}
	};

	if (isEditing && loading)
		return (
			<Container>
				<LinearProgress />
			</Container>
		);

	if (isEditing && error)
		return (
			<Container>
				<Alert severity="error">Impossibile caricare il contatto</Alert>
			</Container>
		);

	return (
		<Container>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Toolbar>
					<Box marginRight={1}>
						<IconButton
							edge="start"
							component={Link}
							to={contactId ? `/contacts/${contactId}` : "/contacts"}
						>
							<ArrowLeft />
						</IconButton>
					</Box>
					<Box flexGrow={1} />
					<Button
						type="submit"
						variant="outlined"
						disabled={!formState.isDirty}
					>
						<Save />
						<Hidden mdDown>Salva modifiche</Hidden>
					</Button>
				</Toolbar>
				{formState.isDirty && (
					<Alert severity="warning">Ci sono delle modifiche da salvare.</Alert>
				)}
				<main>
					<Grid container spacing={2}>
						<Grid item xs={12} md={9}>
							<TextField
								inputRef={register}
								name="label"
								label="Nome"
								variant="outlined"
								fullWidth
							/>
						</Grid>

						<Grid item xs={12} md={4}>
							<TextField
								inputRef={register}
								name="phone"
								label="Telefono"
								variant="outlined"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<TextField
								inputRef={register}
								name="email"
								label="Email"
								variant="outlined"
								fullWidth
							/>
						</Grid>
					</Grid>
				</main>
			</form>
			<DevTool control={control} />
		</Container>
	);
};

export default ContactEditor;
