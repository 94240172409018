import {
	Box,
	Container as MuiContainer,
	Grid,
	Icon,
	IconButton,
	LinearProgress,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Toolbar,
	Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useGet } from "@typesaurus/react";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Delete, Edit, FileText, Trash, X } from "react-feather";
import {
	HiGlobe,
	HiLocationMarker,
	HiOutlineGlobe,
	HiOutlineGlobeAlt,
	HiOutlineLocationMarker,
	HiOutlineMail,
	HiOutlinePhone,
	HiOutlineTag,
} from "react-icons/hi";
import { Link, useHistory, useParams } from "react-router-dom";
import { collection } from "typesaurus";

import { firestore, storage } from "../../config/firebase";
import { DatabaseContact } from "../../types/firebase/contacts";
import { AttachmentBox, Container, Info, Picture } from "./styles";

const contacts = collection<DatabaseContact>("contacts");

const Contact: React.FC = () => {
	const history = useHistory();
	const { contactId } = useParams<{ contactId: string }>();
	const [contact, { loading, error }] = useGet(contacts, contactId);

	return (
		<Container>
			<Toolbar>
				<Box flexGrow={1} />
				<IconButton
					onClick={async () => {
						await firestore.collection("contacts").doc(contactId).delete();
						history.push("/contacts");
					}}
				>
					<Trash />
				</IconButton>
				<IconButton component={Link} to={`/contacts/${contactId}/edit`}>
					<Edit />
				</IconButton>
				<IconButton edge="end" component={Link} to="/contacts">
					<X />
				</IconButton>
			</Toolbar>
			{error && <Alert severity="error">Impossibile caricare il luogo</Alert>}
			{loading && <LinearProgress />}
			{contact && (
				<MuiContainer>
					<Info>
						<Typography>
							{/* {format(contact.data.publishingDate, "dd MMM yyyy")} */}
						</Typography>
					</Info>
					<Typography variant="h3">{contact.data.label}</Typography>
					<Grid container spacing={3}>
						<Grid item>
							<List>
								<ListItem>
									<ListItemIcon>
										<Icon>
											<HiOutlineMail />
										</Icon>
									</ListItemIcon>
									<ListItemText primary={contact.data.email} />
								</ListItem>
								<ListItem>
									<ListItemIcon>
										<Icon>
											<HiOutlinePhone />
										</Icon>
									</ListItemIcon>
									<ListItemText primary={contact.data.phone} />
								</ListItem>
							</List>
						</Grid>
					</Grid>
				</MuiContainer>
			)}
		</Container>
	);
};

export default Contact;
