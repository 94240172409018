import {
	Box,
	Container as MuiContainer,
	Grid,
	Icon,
	IconButton,
	LinearProgress,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Toolbar,
	Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useGet } from "@typesaurus/react";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Delete, Edit, FileText, Trash, X } from "react-feather";
import {
	HiGlobe,
	HiLocationMarker,
	HiOutlineGlobe,
	HiOutlineGlobeAlt,
	HiOutlineLocationMarker,
	HiOutlineMail,
	HiOutlinePhone,
	HiOutlineTag,
} from "react-icons/hi";
import { Link, useHistory, useParams } from "react-router-dom";
import { collection } from "typesaurus";

import { firestore, storage } from "../../config/firebase";
import { DatabasePlace } from "../../types/firebase/places";
import { AttachmentBox, Container, Info, Picture } from "./styles";

const places = collection<DatabasePlace>("places");

const Place: React.FC = () => {
	const history = useHistory();
	const { placeId } = useParams<{ placeId: string }>();
	const [place, { loading, error }] = useGet(places, placeId);

	return (
		<Container>
			<Toolbar>
				<Box flexGrow={1} />
				<IconButton
					onClick={async () => {
						await firestore.collection("places").doc(placeId).delete();
						history.push("/places");
					}}
				>
					<Trash />
				</IconButton>
				<IconButton component={Link} to={`/places/${placeId}/edit`}>
					<Edit />
				</IconButton>
				<IconButton edge="end" component={Link} to="/places">
					<X />
				</IconButton>
			</Toolbar>
			{error && <Alert severity="error">Impossibile caricare il luogo</Alert>}
			{loading && <LinearProgress />}
			{place && (
				<MuiContainer>
					<Info>
						<Typography>
							{/* {format(place.data.publishingDate, "dd MMM yyyy")} */}
						</Typography>
					</Info>
					<Typography variant="h3">{place.data.name}</Typography>
					<Grid container spacing={3}>
						<Grid item xs={12} md={6}>
							{place.data.coords && (
								<Picture
									src={`https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/static/pin-l-star+000(${place.data.coords?.T_},${place.data.coords?.w_})/${place.data.coords?.T_},${place.data.coords?.w_},14,0/512x320@2x?access_token=pk.eyJ1IjoiZWwxZmxlbSIsImEiOiJja2h2NHJteXUwYmxxMnZvMnZnaTk5MTM5In0.EiQAhfCUuMgCNdfX_9YcEg`}
									alt=""
								/>
							)}
						</Grid>
						<Grid item>
							<List>
								<ListItem>
									<ListItemIcon>
										<Icon>
											<HiOutlineLocationMarker />
										</Icon>
									</ListItemIcon>
									<ListItemText primary={place.data.address} />
								</ListItem>
								<ListItem>
									<ListItemIcon>
										<Icon>
											<HiOutlineGlobe />
										</Icon>
									</ListItemIcon>
									<ListItemText
										primary={`${place.data.coords?.T_},${place.data.coords?.w_}`}
									/>
								</ListItem>
								<ListItem>
									<ListItemIcon>
										<Icon>
											<HiOutlineMail />
										</Icon>
									</ListItemIcon>
									<ListItemText primary={place.data.email} />
								</ListItem>
								<ListItem>
									<ListItemIcon>
										<Icon>
											<HiOutlinePhone />
										</Icon>
									</ListItemIcon>
									<ListItemText primary={place.data.phone} />
								</ListItem>
								<ListItem>
									<ListItemIcon>
										<Icon>
											<HiOutlineGlobeAlt />
										</Icon>
									</ListItemIcon>
									<ListItemText primary={place.data.website} />
								</ListItem>
								<ListItem>
									<ListItemIcon>
										<Icon>
											<HiOutlineTag />
										</Icon>
									</ListItemIcon>
									<ListItemText primary={place.data.type} />
								</ListItem>
							</List>
						</Grid>
					</Grid>
				</MuiContainer>
			)}
		</Container>
	);
};

export default Place;
