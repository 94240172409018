import firebaseCore from "firebase/app";

import "firebase/firestore";
import "firebase/auth";
import "firebase/analytics";
import "firebase/performance";
import "firebase/storage";

export const firebaseConfig = {
	apiKey: "AIzaSyDiR-a3QVarlNk-qN4aTaqk6Wf6g3_3Olg",
	authDomain: "el1flem-bard.firebaseapp.com",
	databaseURL: "https://el1flem-bard.firebaseio.com",
	projectId: "el1flem-bard",
	storageBucket: "el1flem-bard.appspot.com",
	messagingSenderId: "127430232767",
	appId: "1:127430232767:web:a540797bb072e8f400140a",
	measurementId: "G-4XMT13CL0D",
};

if (firebaseCore.apps.length === 0) {
	firebaseCore.initializeApp(firebaseConfig);
}

export const firebase = firebaseCore;
export const firestore = firebaseCore.firestore();
export const storage = firebaseCore.storage();
export const auth = firebaseCore.auth();
export const creationAuth = firebaseCore.auth();
export const GeoPoint = firebaseCore.firestore.GeoPoint;
