import { Fab } from "@material-ui/core";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";

const Container = styled.div`
	display: grid;
	grid-template-areas: "list detail";
	grid-template-columns: minmax(320px, 384px) 1fr;

	${({ theme }) => theme.breakpoints.down("sm")} {
		grid-template-areas: "detail" "list";
		grid-template-columns: 1fr;
	}

	& > *:not(:last-child) {
		border-right: 1px solid ${({ theme }) => theme.palette.divider};
	}

	.Mui {
		&List {
			&-root {
				height: calc(100vh - 64px);
				overflow-y: auto;

				${({ theme }) => theme.breakpoints.down("sm")} {
					&:not(:only-child) {
						display: none;
					}
				}
			}
		}
	}
`;

export const PlacesFab = styled(Fab).attrs({
	variant: "extended",
	color: "primary",
	component: Link,
})`
	bottom: ${({ theme }) => theme.spacing(2)}px;
	position: fixed;
	right: ${({ theme }) => theme.spacing(2)}px;

	svg {
		margin-right: ${({ theme }) => theme.spacing(1)}px;
	}
`;

export { Container };
