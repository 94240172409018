import React from "react";
import {
	BrowserRouter as Router,
	Redirect,
	Route,
	Switch,
} from "react-router-dom";

import DashboardLayout from "../components/DashboardLayout";
import RestrictedRoute from "../components/RestrictedRoute";
import Article from "./Article";
import ArticleEditor from "./ArticleEditor";
import ArticleList from "./ArticleList";
import Auth from "./Auth";
import Contact from "./Contact";
import ContactEditor from "./ContactEditor";
import ContactList from "./ContactList";
import Place from "./Place";
import PlaceEditor from "./PlaceEditor";
import PlaceList from "./PlaceList";
import User from "./User";
import UserEditor from "./UserEditor";
import UserList from "./UserList";

const Routes: React.FC = () => {
	console.log("routes");

	return (
		<Router>
			<Switch>
				<RestrictedRoute requiresGuest path="/auth">
					<Switch>
						<Route path="/auth" component={Auth} />
					</Switch>
				</RestrictedRoute>

				<RestrictedRoute requiresAuth path="/articles">
					<DashboardLayout>
						<ArticleList>
							<Switch>
								<Route path="/articles/new" component={ArticleEditor} />
								<Route
									path="/articles/:articleId/edit"
									component={ArticleEditor}
								/>
								<Route path="/articles/:articleId" component={Article} />
							</Switch>
						</ArticleList>
					</DashboardLayout>
				</RestrictedRoute>

				<RestrictedRoute requiresAuth path="/places">
					<DashboardLayout>
						<PlaceList>
							<Switch>
								<Route path="/places/new" component={PlaceEditor} />
								<Route path="/places/:placeId/edit" component={PlaceEditor} />
								<Route path="/places/:placeId" component={Place} />
							</Switch>
						</PlaceList>
					</DashboardLayout>
				</RestrictedRoute>

				<RestrictedRoute requiresAuth path="/contacts">
					<DashboardLayout>
						<ContactList>
							<Switch>
								<Route path="/contacts/new" component={ContactEditor} />
								<Route
									path="/contacts/:contactId/edit"
									component={ContactEditor}
								/>
								<Route path="/contacts/:contactId" component={Contact} />
							</Switch>
						</ContactList>
					</DashboardLayout>
				</RestrictedRoute>

				<RestrictedRoute requiresAuth path="/users">
					<DashboardLayout>
						<UserList>
							<Switch>
								<Route path="/users/new" component={UserEditor} />
								<Route path="/users/:userId/edit" component={UserEditor} />
								<Route path="/users/:userId" component={User} />
							</Switch>
						</UserList>
					</DashboardLayout>
				</RestrictedRoute>

				<Redirect path="/" to="/articles" />
			</Switch>
		</Router>
	);
};

export default Routes;
