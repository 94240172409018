import {
	Avatar,
	Box,
	IconButton,
	LinearProgress,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Menu,
	MenuItem,
	Toolbar,
	Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useOnQuery } from "@typesaurus/react";
import React, { useMemo, useState } from "react";
import { Plus } from "react-feather";
import { HiSortDescending as SortingIcon } from "react-icons/hi";
import { Link, Route, useHistory } from "react-router-dom";
import { collection, order } from "typesaurus";

import { DatabaseContact } from "../../types/firebase/contacts";
import { ContactsFab, Container } from "./styles";

export interface ContactListProperties {
	children: JSX.Element;
}

const contacts = collection<DatabaseContact>("contacts");

const ContactList: React.FC<ContactListProperties> = ({
	children,
}: ContactListProperties): JSX.Element => {
	const history = useHistory();
	const [contactsList, { loading, error }] = useOnQuery(contacts, [
		order("label", "desc"),
	]);

	const listContent = useMemo(
		() =>
			contactsList?.map(({ ref, data }) => (
				<ListItem
					key={ref.id}
					component={Link}
					to={`/contacts/${ref.id}`}
					button
				>
					<ListItemText
						primary={data.label}
						secondary={data.phone ?? data.email}
					/>
				</ListItem>
			)),
		[contactsList],
	);

	return (
		<>
			{loading && <LinearProgress />}
			{contactsList && (
				<Container>
					<List>
						{error && (
							<Alert severity="error">Impossibile caricare gli contatti</Alert>
						)}
						<Toolbar>
							<Typography variant="h3">Contatti</Typography>
						</Toolbar>
						{listContent}
					</List>
					{children}
				</Container>
			)}
			<Route exact path="/contacts">
				<ContactsFab
					onClick={(event) => {
						event.stopPropagation();
						history.push("/contacts/new");
					}}
				>
					<Plus />
					Crea Contatto
				</ContactsFab>
			</Route>
		</>
	);
};

export default ContactList;
