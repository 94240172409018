import { DevTool } from "@hookform/devtools";
import {
	Box,
	Button,
	FormControl,
	FormControlLabel,
	Grid,
	Hidden,
	IconButton,
	InputLabel,
	LinearProgress,
	MenuItem,
	Select,
	Switch,
	TextField,
	Toolbar,
	Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useGet } from "@typesaurus/react";
import { da } from "date-fns/esm/locale";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { ArrowLeft, FileText, Save } from "react-feather";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";
import { collection } from "typesaurus";

import {
	creationAuth,
	firebase,
	firestore,
	GeoPoint,
	storage,
} from "../../config/firebase";
import { DatabaseUser, LocalUser } from "../../types/firebase/users";
import { AttachmentBox } from "../User/styles";
import { AttachmentUploader, Container } from "./styles";

const roles = ["ADMIN", "EDITOR", "VIEWER"] as const;

const users = collection<DatabaseUser>("users");

const UserEditor = (): JSX.Element => {
	const history = useHistory();
	const { userId } = useParams<{ userId: string }>();
	const [user, { loading, error }] = useGet(users, userId);
	const {
		register,
		control,
		reset,
		handleSubmit,
		errors,
		formState,
		getValues,
		setValue,
	} = useForm<LocalUser>({
		defaultValues: {
			firstName: user?.data.firstName,
			lastName: user?.data.lastName ?? "",
			email: user?.data.email,
			role: user?.data.role,
		},
	});

	const values = getValues();

	useEffect(() => {
		reset({
			firstName: user?.data.firstName,
			lastName: user?.data.lastName ?? "",
			email: user?.data.email,
			role: user?.data.role,
		});
	}, [user, loading, reset]);

	const isEditing = Boolean(userId);

	const onSubmit: SubmitHandler<LocalUser> = async (data) => {
		if (!data.firstName) return;
		if (!data.email) return;
		if (!data.role) return;

		const userPayload: DatabaseUser = {
			firstName: data.firstName,
			lastName: data.lastName,
			email: data.email,
			role: data.role,
		};

		if (isEditing) {
			firestore.collection("users").doc(userId).update(userPayload);
			history.push(`/users/${userId}`);
		} else {
			const pwd = await window.prompt(
				"Inserisci la password per questo utente.",
			);
			if (!pwd) return;
			await creationAuth.createUserWithEmailAndPassword(data.email, pwd);

			const result = await firestore
				.collection("users")
				.doc(creationAuth.currentUser?.uid)
				.set(userPayload);
			history.push("/users");
		}
	};

	if (isEditing && loading)
		return (
			<Container>
				<LinearProgress />
			</Container>
		);

	if (isEditing && error)
		return (
			<Container>
				<Alert severity="error">Impossibile caricare l utente</Alert>
			</Container>
		);

	return (
		<Container>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Toolbar>
					<Box marginRight={1}>
						<IconButton
							edge="start"
							component={Link}
							to={userId ? `/users/${userId}` : "/users"}
						>
							<ArrowLeft />
						</IconButton>
					</Box>
					<Box flexGrow={1} />
					<Button
						type="submit"
						variant="outlined"
						disabled={!formState.isDirty}
					>
						<Save />
						<Hidden mdDown>Salva modifiche</Hidden>
					</Button>
				</Toolbar>
				{formState.isDirty && (
					<Alert severity="warning">Ci sono delle modifiche da salvare.</Alert>
				)}
				<main>
					<Grid container spacing={2}>
						<Grid item xs={12} md={4}>
							<TextField
								inputRef={register}
								name="firstName"
								label="Nome"
								variant="outlined"
								fullWidth
							/>
						</Grid>

						<Grid item xs={12} md={4}>
							<TextField
								inputRef={register}
								name="lastName"
								label="Cognome"
								variant="outlined"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<FormControl variant="outlined" fullWidth>
								<InputLabel id="Ruolo">Ruolo</InputLabel>
								<Controller
									as={
										<Select labelId="Ruolo" label="Ruolo">
											{roles.map((role) => (
												<MenuItem key={role} value={role}>
													{role}
												</MenuItem>
											))}
										</Select>
									}
									name="role"
									control={control}
								/>
							</FormControl>
						</Grid>
						<Grid item xs={12} md={12}>
							<TextField
								inputRef={register}
								name="email"
								label="Email"
								variant="outlined"
								disabled={isEditing}
								fullWidth
							/>
						</Grid>
					</Grid>
				</main>
			</form>
			<DevTool control={control} />
		</Container>
	);
};

export default UserEditor;
