import { DevTool } from "@hookform/devtools";
import {
	Box,
	Button,
	FormControl,
	FormControlLabel,
	Grid,
	Hidden,
	IconButton,
	InputLabel,
	LinearProgress,
	MenuItem,
	Select,
	Switch,
	TextField,
	Toolbar,
	Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useGet } from "@typesaurus/react";
import { format } from "date-fns";
import { da } from "date-fns/esm/locale";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { ArrowLeft, FileText, Save } from "react-feather";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";
import { collection } from "typesaurus";

import { firestore, storage } from "../../config/firebase";
import { DatabaseArticle, LocalArticle } from "../../types/firebase/articles";
import { AttachmentBox } from "../Article/styles";
import { AttachmentUploader, Container } from "./styles";

const categories = [
	"avvisi",
	"allerte",
	"comunità",
	"delibere",
	"ordinanze",
	"novità",
] as const;

const articles = collection<DatabaseArticle>("articles");

const ArticleEditor = (): JSX.Element => {
	const history = useHistory();
	const { articleId } = useParams<{ articleId: string }>();
	const [article, { loading, error }] = useGet(articles, articleId);
	const {
		register,
		control,
		reset,
		handleSubmit,
		errors,
		formState,
		getValues,
		setValue,
	} = useForm<LocalArticle>({
		defaultValues: {
			title: article?.data.title,
			plainTextBody: article?.data.plainTextBody,
			isPublished: article?.data.isPublished ?? false,
			attachments: article?.data.attachments ?? [],
			gallery: article?.data.gallery ?? [],
			categories: article?.data?.categories?.[0] ?? "",
			gravity: article?.data?.gravity ?? "low",
		},
	});

	const [isUploadingGallery, setIsUploadingGallery] = useState(false);
	const [isUploadingAttachments, setIsUploadingAttachments] = useState(false);
	const [attachments, setAttachments] = useState<any>();

	const values = getValues();

	useEffect(() => {
		reset({
			title: article?.data.title,
			plainTextBody: article?.data.plainTextBody,
			isPublished: article?.data.isPublished ?? false,
			attachments: article?.data.attachments ?? [],
			gallery: article?.data.gallery ?? [],
			categories: article?.data?.categories?.[0] ?? "",
			gravity: article?.data?.gravity ?? "low",
		});
	}, [article, loading, reset]);

	useEffect(() => {
		const downloadFiles = async () => {
			if (!values.attachments || values.attachments.length === 0) return;
			const detailedAttachments = await Promise.all(
				values.attachments.map(async (attachment) => {
					const attachmentReference = await storage.refFromURL(attachment);
					const attachmentMeta = await attachmentReference.getMetadata();
					const attachmentDownloadUrl = await attachmentReference.getDownloadURL();

					return {
						meta: attachmentMeta,
						downloadUrl: attachmentDownloadUrl,
					};
				}),
			);

			setAttachments(detailedAttachments);
		};

		downloadFiles();
	}, [values]);

	const uploadFile = (file: File, type: "attachments" | "gallery") => {
		const storageReference = storage
			.ref()
			.child(
				`/articles/${type}/${format(new Date(), "yyyyMMdd")}/${format(
					new Date(),
					"HHmm",
				)}${file.name}`,
			);
		return storageReference.put(file);
	};

	const uploadFiles = async (
		files: File[],
		type: "attachments" | "gallery",
	) => {
		type === "gallery"
			? setIsUploadingGallery(true)
			: setIsUploadingAttachments(true);

		const fileReferences = await Promise.all(
			files.map((file) => uploadFile(file, type)),
		);

		if (type === "attachments")
			fileReferences.map((fileReference) => {
				setValue("attachments", [
					...(getValues().attachments ?? []),
					`gs://el1flem-bard.appspot.com/${fileReference.ref.fullPath}`,
				]);
			});

		if (type === "gallery")
			fileReferences.map(async (fileReference) => {
				const fileUrl = await fileReference.ref.getDownloadURL();
				setValue("gallery", [...(getValues().gallery ?? []), fileUrl]);
			});

		type === "gallery"
			? setIsUploadingGallery(false)
			: setIsUploadingAttachments(false);
	};

	const isEditing = Boolean(articleId);

	const onSubmit: SubmitHandler<LocalArticle> = async (data) => {
		const articlePayload: DatabaseArticle = {
			title: data.title,
			plainTextBody: data.plainTextBody,
			publishingDate: article?.data.publishingDate ?? new Date(),
			lastEdit: new Date(),
			isPublished: data.isPublished,
			attachments: data.attachments,
			gallery: data.gallery,
			categories: [data.categories],
			gravity: data.gravity,
		};

		if ((data.gallery?.length || 0) > 0)
			articlePayload.mainPicture = data.gallery?.[0];

		if (isEditing) {
			firestore.collection("articles").doc(articleId).update(articlePayload);
			history.push(`/articles/${articleId}`);
		} else {
			const result = await firestore.collection("articles").add(articlePayload);
			history.push(result.id);
		}
	};

	if (isEditing && loading)
		return (
			<Container>
				<LinearProgress />
			</Container>
		);

	if (isEditing && error)
		return (
			<Container>
				<Alert severity="error">Impossibile caricare l&apos;articolo</Alert>
			</Container>
		);

	return (
		<Container>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Toolbar>
					<Box marginRight={1}>
						<IconButton
							edge="start"
							component={Link}
							to={articleId ? `/articles/${articleId}` : "/articles"}
						>
							<ArrowLeft />
						</IconButton>
					</Box>
					<FormControlLabel
						control={
							<Controller
								control={control}
								name="isPublished"
								render={({ onChange, onBlur, value, ref }) => (
									<Switch
										color="primary"
										onBlur={onBlur}
										onChange={(event: ChangeEvent<HTMLInputElement>) =>
											onChange(event.target.checked)
										}
										checked={value}
										inputRef={ref}
									/>
								)}
							/>
						}
						label="Pubblico"
					/>
					<Box flexGrow={1} />
					<Button
						type="submit"
						variant="outlined"
						disabled={
							!formState.isDirty || isUploadingAttachments || isUploadingGallery
						}
					>
						<Save />
						<Hidden mdDown>Salva modifiche</Hidden>
					</Button>
				</Toolbar>
				{formState.isDirty && (
					<Alert severity="warning">Ci sono delle modifiche da salvare.</Alert>
				)}
				<main>
					<Grid container spacing={2}>
						<Grid item xs={12} md={7}>
							<TextField
								inputRef={register}
								name="title"
								label="Titolo"
								variant="outlined"
								fullWidth
							/>
						</Grid>
						<Grid item xs={6} md={3}>
							<FormControl variant="outlined" fullWidth>
								<InputLabel id="Categoria">Categoria</InputLabel>
								<Controller
									as={
										<Select labelId="Categoria" label="Categoria">
											{categories.map((category) => (
												<MenuItem key={category} value={category}>
													{category}
												</MenuItem>
											))}
										</Select>
									}
									name="categories"
									control={control}
								/>
							</FormControl>
						</Grid>
						<Grid item xs={6} md={2}>
							<FormControl variant="outlined" fullWidth>
								<InputLabel id="Gravita">Gravità</InputLabel>
								<Controller
									as={
										<Select labelId="gravity" label="Gravità">
											<MenuItem value={"high"}>Alta</MenuItem>
											<MenuItem value={"medium"}>Media</MenuItem>
											<MenuItem value={"low"}>Bassa</MenuItem>
										</Select>
									}
									name="gravity"
									control={control}
								/>
							</FormControl>
						</Grid>
						<Grid item xs={12} md={12}>
							<TextField
								inputRef={register}
								name="plainTextBody"
								label="Corpo dell'articolo"
								variant="outlined"
								multiline
								rows={10}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<Typography variant="h5">Allegati</Typography>
							<Controller
								name="attachments"
								control={control}
								render={(properties) => {
									return (
										<Dropzone
											onDrop={(acceptedFiles) => {
												uploadFiles(acceptedFiles, "attachments");
											}}
										>
											{({ getRootProps, getInputProps }) => {
												return (
													<AttachmentUploader>
														{isUploadingAttachments && <LinearProgress />}
														{(attachments || []).map((attachment: any) => (
															<AttachmentBox key={attachment.downloadUrl}>
																<a
																	href={attachment.downloadUrl}
																	download={attachment.meta.name}
																	target="_blank"
																	rel="noreferrer"
																>
																	<FileText />
																	<div>
																		<Typography>
																			{attachment.meta.name}
																		</Typography>
																		<Typography>
																			{attachment.meta.size}
																		</Typography>
																	</div>
																</a>
															</AttachmentBox>
														))}
														<div {...getRootProps()}>
															<input {...getInputProps()} />
															<Button
																color="primary"
																size="large"
																variant="outlined"
																fullWidth
															>
																Trascina gli allegati che vuoi inserire o clicca
																qui
															</Button>
														</div>
													</AttachmentUploader>
												);
											}}
										</Dropzone>
									);
								}}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<Typography variant="h5">Galleria</Typography>
							<Controller
								name="gallery"
								control={control}
								render={(properties) => {
									return (
										<Dropzone
											onDrop={(acceptedFiles) => {
												uploadFiles(acceptedFiles, "gallery");
											}}
										>
											{({ getRootProps, getInputProps }) => (
												<AttachmentUploader>
													{isUploadingGallery && <LinearProgress />}
													{((properties.value || []) as string[]).map(
														(picture) => (
															<img key={picture} src={picture} alt="" />
														),
													)}
													<div {...getRootProps()}>
														<input {...getInputProps()} />
														<Button
															color="primary"
															size="large"
															variant="outlined"
															fullWidth
														>
															Trascina le immagini che vuoi inserire o clicca
															qui
														</Button>
													</div>
												</AttachmentUploader>
											)}
										</Dropzone>
									);
								}}
							/>
						</Grid>
					</Grid>
				</main>
			</form>
			<DevTool control={control} />
		</Container>
	);
};

export default ArticleEditor;
