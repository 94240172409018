import { AppBar } from "@material-ui/core";
import styled from "styled-components/macro";

const Container = styled.div`
	display: grid;
	grid-template-areas: "AppBar AppBar" "Sidebar Content";
	grid-template-columns: 64px 1fr;
	grid-template-rows: 64px 1fr;
`;

export const DashboardAppbar = styled(AppBar).attrs({
	color: "inherit",
})`
	grid-area: AppBar;
`;

export const DashboardBody = styled.main`
	grid-area: Content;
`;

export const UserMenu = styled.div`
	min-width: 256px;
`;

export const SideBarContainer = styled.div`
	grid-area: Sidebar;
`;

export { Container };
